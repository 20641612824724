<template>
  <div class="duration-wrapper padding-top-layout auto-approval-content">
    <div class="padding-left-40">
      <page-title :title="$t('setting.title_setting_auto_approval')" :textOnlyJp="$t('offset_transaction.title_only_jp')" />
    </div>
    <div class="auto-approval">
      <div class="setting-approval">
        <div class="setting-approval-label mb-4">{{ $t('auto_approval.label_change_setting_approval')}}</div>
        <RadioSelect v-model="value" :defaultValue="value" :items="items" />
      </div>
      <input class="submit-btn" type="button" :value="buttonChangeSettingApprove" @click="handleUpdateSettingWorkFollow" />
    </div>
    <notification-popup :dialog="dialogNotification" :message="messageSavedChange" @submit="dialogNotification = false" confirmText="決定" titleText="通知" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ROUTES } from '@/router/constants';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import { getAutoApprovalSetting, updateAutoApprovalSetting } from '@/api/autoApproval';
import NotificationPopup from "@/components/dialogs/notification-popup.vue";
import RadioSelect from "@/components/commonApp/RadioCustom/index.vue";
import {ROLE} from '@/constants/role';
import PageTitle from '@/components/pageTitle/pageTitle.vue';

export default {
  name: 'SettingAutoApproval',
  components: { NotificationPopup, ErrorPopup, RadioSelect, PageTitle },
  data() {
    return {
      breadcrumb: [
        {
          text: 'ダッシュボード',
          disabled: false,
          href: ROUTES.PRODUCTS + ROUTES.HOME_DASHBOARD,
        },
        {
          text: '設定',
          disabled: false,
          href: ROUTES.PRODUCTS + ROUTES.SETTING
        },
        {
          text: this.$t('setting.title_setting_auto_approval'),
          disabled: false,
          href: ROUTES.PRODUCTS + ROUTES.SETTING_AUTO_APPROVAL,
        },
      ],
      items: [
        {
          id: 1,
          name: this.$t('auto_approval.checkbox_auto_approval')
        },
        {
          id: 0,
          name: this.$t('auto_approval.checkbox_not_auto_approval')
        }
      ],
      value: 0,
      dialogNotification: false
    };
  },

  computed: {
    ...mapState('userData', ['currentUser']),
    buttonChangeSettingApprove() {
      return this.$t('auto_approval.button_change_setting_approval');
    },
    messageSavedChange() {
      return this.$t('auto_approval.message_saved_changes')
    }
  },

  async mounted() {
    if(this.currentUser.user.role_id === ROLE.USER) {
      this.$router.push({path: '/products/setting'})
    }
    this.updateBreadCrumb(this.breadcrumb);
    await this.handleGetSettingWorkFollow()
  },

  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),

    async handleGetSettingWorkFollow() {
      try {
        const response = await getAutoApprovalSetting()
        this.value = response.data.auto_approve ? 1 : 0
      } catch (error) {
        console.warn(error);
      }
    },
    async handleUpdateSettingWorkFollow() {
      try {
        const data = {
          is_auto: this.value
        }
        await updateAutoApprovalSetting(data)
        this.dialogNotification = true
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>
<style lang="scss">

</style>
<style lang="scss" scoped>
.duration-wrapper {
  h1 {
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: $goldMid;
    margin: 40px 0px 80px;
  }
  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-align: left;
    color: $monoBlack;
    margin-bottom: 80px;
  }
}
.submit-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 2px 20px;
  background: $goldMid;
  width: 100%;
  height: 56px;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  color: $monoWhite;
  &:hover {
    /* Gold/Light */

    background: $goldLight;
    box-shadow: 0px 4px 14px rgba(160, 181, 186, 0.167237), 0px 1.20588px 4.22059px rgba(160, 181, 186, 0.24),
      0px 0.500862px 1.75302px rgba(160, 181, 186, 0.312763), 0px 0.181152px 0.634032px rgba(160, 181, 186, 0.48);
  }
  &:focus-within {
    // border: 2px solid $blueMid;
    padding: 14px 0px 18px;
    color: $monoWhite;
  }
}
.auto-approval-content {
  .page-title {
    margin: 40px 0px 80px;
  }
  @media only screen and (min-width: 1024px) {
    .page-title {
      margin: 0 0 80px;
    }
  }
}
.auto-approval {
  display: flex;
  width: 100%;
  max-width: 600px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  margin: 0 auto;
  .setting-approval {
    &-label {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.42px;
      color: $monoBlack;
    }
  }
}
@include desktop {
  .duration-wrapper {
    h1 {
      margin: 0 0 80px;
    }
    form {
      width: 600px;
      margin: 0 auto;
    }
  }

  .padding-left-40 {
    padding-left: 40px;
  }

  .submit-btn:focus {
    background-color: $goldMid !important;
    opacity: 1;
  }
}
</style>
